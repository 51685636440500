export default function getColor(status) {
  switch (status) {
    case 'PENDING':
      return 'warning';
    case 'APPROVED':
      return 'success';
    case 'REJECTED':
      return 'danger';
    case 'DELETED':
      return 'danger';
    default:
      return '';
  }
}
