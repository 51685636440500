<template>
  <el-table
    :data="items"
    style="width: 100%"
    :border="true"
    highlight-current-row
    row-key="id"
    :expand-row-keys="expandRowKeys"
    @expand-change="handleExpandChange"
    v-loading="isLoading"
  >
    <el-table-column label="Title">
      <template #default="scope">
        <p class="break-word">{{ scope.row.title }}</p>
      </template>
    </el-table-column>
    <el-table-column prop="creation_date" label="Submitted" width="120" />
    <el-table-column prop="user_name" label="User" width="180" />
    <el-table-column
      prop="admin_name"
      label="Admin"
      width="180"
      v-if="activeTab !== 'pending'"
    />
    <el-table-column label="Status" width="120">
      <template #default="scope">
        <el-tag :type="getColor(scope.row.status)">{{
          scope.row.status
        }}</el-tag>
      </template>
    </el-table-column>
    <el-table-column v-if="activeTab === 'approved'" width="70">
      <template #default="scope">
        <div class="text-center cursor-pointer">
          <i
            class="bi bi-star-fill color-success fs-20"
            v-if="scope.row.is_featured"
            @click="handleFeatured(scope.row, false)"
          />
          <i
            class="bi bi-star fs-20"
            v-else
            @click="handleFeatured(scope.row, true)"
          />
        </div>
      </template>
    </el-table-column>
    <el-table-column type="expand">
      <template #default="scope">
        <div class="action-btn-group cursor-pointer">
          <span
            class="action-btn"
            v-for="action in actions"
            :class="`color-${action.color} cursor-pointer`"
            @click="handleClick(action.value, scope.row)"
            >{{ action.label }}</span
          >
        </div>
        <div class="row reject-item" v-if="activeTab === 'rejected'">
          <div class="col-12">
            <p>
              <span class="text-bold color-danger">Rejection: </span
              >{{ scope.row.comment }}
            </p>
          </div>
        </div>
        <news-item :item="scope.row" @replaceImg="handleReplace(scope.row)" />
      </template>
    </el-table-column>
  </el-table>
  <!-- Replace image modal -->
  <el-dialog v-model="showModal" destroy-on-close title="Replace image">
    <el-tabs v-model="modalTab" class="tabs" @tab-click="handleTabChange">
      <el-tab-pane label="Upload image" name="upload" />
      <el-tab-pane label="Select from media library" name="select" />
      <el-tab-pane label="Enter image link" name="link" />
    </el-tabs>

    <div v-if="modalTab === 'select'" class="mt-2 mb-4">
      <images-table @selected="handleSelected" />
    </div>
    <div v-if="modalTab === 'upload'" class="mt-4 mb-4">
      <upload-item @selected="handleSelected" />
    </div>
    <div v-if="modalTab === 'link'" class="mt-4 mb-4">
      <el-input
        v-model="imgUrl"
        placeholder="Please enter image link"
        size="large"
      />
    </div>
    <div class="row">
      <div class="col-12 col-lg-6">
        <div>
          <button class="btn-grey fs-16" @click="showModal = false">
            Cancel
          </button>
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <div class="btn-right">
          <button class="btn fs-16" @click.prevent="handleSubmit">
            Update
          </button>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import NewsItem from '@/components/items/News';
import ImagesTable from '@/components/tables/Images';
import UploadItem from '@/components/items/Upload';
import Database from '@/utils/services/Database';

import getColor from '@/utils/options/getColor';

export default {
  name: 'NewsTable',
  props: ['items', 'isLoading', 'actions', 'activeTab'],
  emits: ['actionClick'],
  components: {
    NewsItem,
    ImagesTable,
    UploadItem,
  },
  data() {
    return {
      expandRowKeys: [],
      showModal: false,
      modalTab: 'upload',
      imgUrl: '',
      activeItem: null,
    };
  },
  methods: {
    async handleFeatured(row, val) {
      const status = await Database.update('news', row.id, {
        is_featured: val,
        update_type: 'featured',
      });

      if (status === 200) {
        return this.$message({
          message: 'News featured status updated',
          type: 'success',
        });
      }
      this.$message({
        message: 'Error updating news featured status',
        type: 'error',
      });
    },
    handleClick(action, item) {
      this.$emit('actionClick', action, item);
    },
    handleExpandChange(row, expandedRows) {
      const length = expandedRows.length;

      if (length) this.expandRowKeys = [row.id];
    },
    handleReplace(item) {
      this.activeItem = item;
      this.imgUrl = '';
      this.showModal = true;
    },
    handleTabChange(tab) {
      this.modalTab = tab.paneName;
    },
    async handleSubmit() {
      const status = await Database.update('news', this.activeItem.id, {
        image: this.imgUrl,
        update_type: 'image',
      });

      if (status === 200) {
        this.$message({
          message: 'News image updated',
          type: 'success',
        });
      } else {
        this.$message({
          message: 'Error updating new image',
          type: 'error',
        });
      }

      this.showModal = false;
    },
    handleSelected(url) {
      this.imgUrl = url;
    },
    getColor(status) {
      return getColor(status);
    },
  },
};
</script>
