import Socket from '@/utils/services/Socket';

export default {
  mounted() {
    const { socket } = Socket.initialize();
    socket.on(this.collection, () => {
      this.getData();
    });
  },
};
