<template>
  <el-table
    :data="items"
    style="width: 100%"
    :border="true"
    highlight-current-row
    v-loading="isLoading"
    row-key="id"
    :expand-row-keys="expandRowKeys"
    @expand-change="handleExpandChange"
  >
    <el-table-column label="Title">
      <template #default="scope">
        <p class="break-word">{{ scope.row.title }}</p>
      </template>
    </el-table-column>
    <el-table-column prop="creation_date" label="Submitted" width="120" />
    <el-table-column prop="user_name" label="User" width="180" />
    <el-table-column
      prop="admin_name"
      label="Admin"
      width="180"
      v-if="activeTab !== 'pending'"
    />
    <el-table-column label="Status" width="120">
      <template #default="scope">
        <el-tag :type="getColor(scope.row.status)">{{
          scope.row.status
        }}</el-tag>
      </template>
    </el-table-column>
    <el-table-column type="expand">
      <template #default="scope">
        <div class="action-btn-group cursor-pointer">
          <span
            class="action-btn"
            v-for="action in actions"
            :class="`color-${action.color} cursor-pointer`"
            @click="handleClick(action.value, scope.row)"
            >{{ action.label }}</span
          >
        </div>
        <div class="row reject-item" v-if="activeTab === 'rejected'">
          <div class="col-12">
            <p>
              <span class="text-bold color-danger">Rejection: </span
              >{{ scope.row.comment }}
            </p>
          </div>
        </div>
        <library-item :item="scope.row" />
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import LibraryItem from '@/components/items/Library.vue';

import getColor from '@/utils/options/getColor';

export default {
  name: 'LibraryTable',
  props: ['items', 'isLoading', 'actions', 'activeTab'],
  emits: ['actionClick'],
  components: {
    LibraryItem,
  },
  data() {
    return {
      expandRowKeys: [],
    };
  },
  methods: {
    handleClick(action, item) {
      this.$emit('actionClick', action, item);
    },
    getColor(status) {
      return getColor(status);
    },
    handleExpandChange(row, expandedRows) {
      const length = expandedRows.length;

      if (length) this.expandRowKeys = [row.id];
    },
  },
};
</script>
