<template>
  <div class="row mt-4">
    <div class="col-12 btn-right pe-4">
      <button class="btn-sm-accent fs-16 mt-2" @click="handleCreate">
        Add {{ collection === 'news' ? 'News' : 'Dataset' }}
      </button>
    </div>
  </div>
  <div class="row mt-4 mb-4">
    <el-tabs v-model="activeTab" class="tabs" @tab-click="handleTabChange">
      <el-tab-pane
        v-for="tab in tabs"
        :key="tab.value"
        :label="tab.label"
        :name="tab.value"
      />
    </el-tabs>
  </div>
  <div class="row mb-4">
    <div class="col-12 btn-right">
      <el-pagination
        background
        layout="prev, pager, next"
        v-model:currentPage="page"
        :total="total"
        :page-size="pageSize"
        @current-change="handlePageChange"
      />
    </div>
  </div>
  <news-table
    v-if="collection === 'news'"
    :items="items"
    :is-loading="isLoading"
    :activeTab="activeTab"
    :actions="actions"
    @actionClick="handleClick"
  />
  <library-table
    v-if="collection === 'library'"
    :items="items"
    :is-loading="isLoading"
    :activeTab="activeTab"
    :actions="actions"
    @actionClick="handleClick"
  />

  <!-- Rejection modal -->
  <el-dialog v-model="showReject" title="Enter your comment">
    <el-form>
      <el-input type="textarea" v-model="rejectComment" />
      <el-button class="mt-2" @click.prevent="rejectItem"> Reject </el-button>
    </el-form>
  </el-dialog>
  <!-- Create/Update modal -->
  <el-dialog v-model="showModal" :destroy-on-close="true">
    <app-form
      :form="form"
      :model="model"
      :rules="rules"
      button="Submit"
      button-side="right"
      :type="formType"
      :itemToUpdate="itemToUpdate"
      @submitted="saveItem"
    />
  </el-dialog>
</template>

<script>
import Database from '@/utils/services/Database';
import actions from '@/utils/config/actions';

import NewsTable from '@/components/tables/News.vue';
import LibraryTable from '@/components/tables/Library.vue';

import useSocket from '@/mixins/useSocket';

export default {
  name: 'PageItem',
  props: ['collection'],
  components: {
    NewsTable,
    LibraryTable,
  },
  mixins: [useSocket],
  data() {
    return {
      activeTab: 'pending',
      tabs: [
        {
          label: 'Pending',
          value: 'pending',
          actions: ['update', 'reject', 'approve'],
        },
        {
          label: 'Approved',
          value: 'approved',
          actions: ['update', 'reject'],
        },
        {
          label: 'Rejected',
          value: 'rejected',
          actions: ['update', 'approve'],
        },
        {
          label: 'Deleted',
          value: 'deleted',
          actions: ['restore'],
        },
      ],
      page: 1,
      total: 1,
      pageSize: 10,
      items: [],
      actions: [],
      activeItem: {},
      rejectComment: '',
      showReject: false,
      showModal: false,
      isLoading: false,
      form: [],
      model: {},
      rules: {},
      itemToUpdate: {},
      formType: 'create',
    };
  },
  async created() {
    this.isLoading = true;
    this.getActions();
    await this.getData();
    this.isLoading = false;
  },
  methods: {
    getActions() {
      const options = this.tabs.find((t) => t.value === this.activeTab).actions;

      let tabActions = [];

      for (let o in options) {
        const action = actions.find((a) => a.value === options[o]);
        tabActions.push(action);
      }

      this.actions = tabActions;
    },
    async getData() {
      this.isLoading = true;
      const { items, total } = await Database.getAll(this.collection, {
        status: this.activeTab.toUpperCase(),
        limit: this.pageSize,
        offset: this.page * this.pageSize - this.pageSize,
      });

      this.items = items;

      this.total = total;
      this.isLoading = false;
    },
    async handleTabChange(tab) {
      this.activeTab = tab.paneName;
      this.getActions();
      await this.getData();
    },
    async handleClick(action, item) {
      switch (action) {
        case 'approve':
          return this.handleApprove(item);
        case 'reject':
          return this.handleReject(item);
        case 'update':
          return this.handleUpdate(item);
        case 'restore':
          return this.handleRestore(item);
        default:
          return;
      }
    },
    async handleApprove(item) {
      const status = await Database.update(this.collection, item.id, {
        status: 'APPROVED',
        update_type: 'status',
      });

      if (status === 200) {
        return this.$message({
          type: 'success',
          message: 'Approval successful.',
          offset: 200,
        });
      }

      this.$message({
        type: 'error',
        message: 'Something went wrong. Please try again later.',
        offset: 200,
      });
    },
    handleReject(item) {
      this.activeItem = item;
      this.showReject = true;
    },
    async rejectItem() {
      const status = await Database.update(
        this.collection,
        this.activeItem.id,
        {
          status: 'REJECTED',
          update_type: 'status',
          user_id: this.activeItem.user_id,
          comment: this.rejectComment,
        }
      );

      if (status === 200) {
        this.showReject = false;
        return this.$message({
          type: 'success',
          message: 'Rejection successful.',
          offset: 200,
        });
      }

      this.$message({
        type: 'error',
        message: 'Something went wrong. Please try again later.',
        offset: 200,
      });
    },
    async getForm() {
      this.form = [];
      this.model = {};
      this.rules = {};
      const { form, model, rules } = await import(
        '@/utils/config/' + this.collection
      );
      this.form = JSON.parse(JSON.stringify(form));
      this.model = JSON.parse(JSON.stringify(model));
      this.rules = JSON.parse(JSON.stringify(rules));
    },
    async handleCreate() {
      this.itemToUpdate = {};
      await this.getForm();
      this.formType = 'create';
      this.showModal = true;
    },
    async handleUpdate(item) {
      await this.getForm();
      this.formType = 'update';
      this.itemToUpdate = item;
      this.showModal = true;
    },
    async handleRestore(item) {
      const status = await Database.update(this.collection, item.id, {
        update_type: 'restore',
      });

      if (status === 200) {
        return this.$message({
          type: 'success',
          message: 'Restoration successful.',
          offset: 200,
        });
      }

      this.$message({
        type: 'error',
        message: 'Something went wrong. Please try again later.',
        offset: 200,
      });
    },
    async saveItem(item) {
      const status =
        this.formType === 'update'
          ? await Database.update(this.collection, this.itemToUpdate.id, {
              ...item,
              update_type: 'update',
            })
          : await Database.create(this.collection, {
              ...item,
              collection: this.collection,
            });

      if (status === 201 || status === 200) {
        this.showModal = false;
        return this.$message({
          type: 'success',
          message: 'Item saved successfully.',
          offset: 200,
        });
      }

      this.$message({
        type: 'error',
        message: 'Something went wrong. Please try again later.',
      });
    },
    async handlePageChange(val) {
      this.page = val;
      await this.getData();
    },
  },
};
</script>

<style lang="scss">
.icon-group {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 1rem 2rem;
  i {
    font-size: 20px;
    &:not(:last-child) {
      margin-right: 0.5rem;
    }
  }
}

.reject-item {
  display: flex;
  justify-content: center;
  align-items: center;
  .col-12 {
    max-width: 80%;
    border: 1px solid $danger;
    margin-bottom: 2rem;
    padding: 1rem;
    background-color: rgba($danger, 0.1);
    p {
      margin-bottom: 0;
    }
  }
}

.el-table {
  margin-bottom: 4rem;
}
</style>
