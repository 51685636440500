<template>
  <div class="col-12 mb-4">
    <el-upload
      :file-list="fileList"
      :limit="1"
      :auto-upload="false"
      :on-change="handleUpload"
      :on-remove="handleRemove"
      list-type="picture-card"
      v-loading="isLoading"
    >
      <template #trigger v-if="showUpload">
        <el-button>Select file</el-button>
      </template>
      <template #tip>
        <div class="el-upload__tip text-red">
          Limit 1 file, only jpg/png files are allowed.
        </div>
      </template>
    </el-upload>
  </div>
</template>

<script>
import Database from '@/utils/services/Database';

export default {
  name: 'UploadItem',
  emits: ['selected'],
  data() {
    return {
      fileList: [],
      imgUrl: '',
      showUpload: true,
      isLoading: false,
    };
  },
  methods: {
    async handleUpload(file) {
      const fileType = file.raw.type;

      if (fileType !== 'image/jpeg' && fileType !== 'image/png') {
        return this.$message({
          type: 'error',
          message: 'Only jpg/png files are allowed.',
          offset: 200,
        });
      }
      try {
        this.isLoading = true
        this.showUpload = false;
        const el = document.getElementsByClassName(
          'el-upload--picture-card'
        )[0];
        el.style.display = 'none';
        const upload = await Database.upload('news', file.raw);
        this.imgUrl = upload.img_url;

        this.isLoading = false;
        this.$emit('selected', this.imgUrl);
      } catch (err) {
        this.$message({
          type: 'error',
          message: 'Something went wrong. Please try again.',
          offset: 200,
        });
      }
    },
    handleRemove() {
      this.imgUrl = null;
      this.showUpload = true;
      this.$emit('selected', this.imgUrl);
      const el = document.getElementsByClassName('el-upload--picture-card')[0];
      el.style.display = 'flex';
    },
  },
};
</script>
