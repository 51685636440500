export default [
    {
        label: 'Approve',
        icon: 'bi-check-circle',
        color: 'success',
        value: 'approve'
    },
    {
        label: 'Reject',
        icon: 'bi-x-circle',
        color: 'danger',
        value: 'reject'
    },
    {
        label: 'Update',
        icon: 'bi-pencil-fill',
        color: 'primary',
        value: 'update'
    },
    {
        label: 'Delete',
        icon: 'bi-trash',
        color: 'danger',
        value: 'delete'
    },
    {
        label: 'Restore',
        icon: 'bi-arrow-clockwise',
        color: 'success',
        value: 'restore'
    }
];