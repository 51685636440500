<template>
  <div class="row media-library-row" v-loading="isLoading">
    <div class="col-12 btn-right mb-4">
      <app-pagination
        :total="total"
        :current-page="page"
        :page-size="pageSize"
        @page-change="handlePageChange"
      />
    </div>
    <div class="col-12 col-md-3" v-for="item in items" :key="item.id">
      <el-card
        class="media-item cursor-pointer"
        :body-style="{ padding: '0px' }"
        :class="selectedItem.id === item.id ? 'is-selected' : ''"
        @click="handleSelect(item)"
      >
        <img :src="item.url" :alt="item.name" />
      </el-card>
    </div>
  </div>
</template>

<script>
import Database from '@/utils/services/Database';

export default {
  name: 'ImagesTable',
  emits: ['selected'],
  data() {
    return {
      items: [],
      selectedItem: {
        id: null,
        url: null,
      },
      total: 1,
      page: 1,
      pageSize: 12,
    };
  },
  async created() {
    this.isLoading = true;
    await this.getData();
    this.isLoading = false;
  },
  methods: {
    async getData() {
      this.isLoading = true;
      const res = await Database.getAll('uploads', {
        limit: this.pageSize,
        offset: this.page * this.pageSize - this.pageSize,
      });

      this.items = res.data;
      this.total = res.total;
      this.isLoading = false;
    },
    handleSelect(item) {
      if (this.selectedItem.id === item.id)
        this.selectedItem = { id: null, url: null };
      else this.selectedItem = item;

      this.$emit('selected', this.selectedItem.url)
    },
    async handlePageChange(val) {
      this.page = val;
      await this.getData();
    },
  },
};
</script>

<style lang="scss">
.media-item {
  height: 150px;
  margin-bottom: 1rem;
  img {
    height: 150px;
    width: 100%;
    display: block;
    object-fit: cover;
  }
  &.is-selected {
    border: 5px solid $success;
  }
}
</style>
